import React from 'react';

const QuickssTab = ({ data }) => {
  return (
    <section className="quickss-tab">
      <div className="container-lg container--1">
        <div className="row">
          <div className="col-lg-6">
            <div className="challenge-set">
              <div className="accordion my-accordion" id="a1">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#challenge" aria-expanded="true" aria-controls="challenge">
                      Challenge
                    </button>
                  </h2>
                  <div id="challenge" className="accordion-collapse collapse show" data-bs-parent="#a1">
                    <div className="accordion-body">
                      <p>{data.challenge}</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#solution" aria-expanded="false" aria-controls="collapseTwo">
                      Solution
                    </button>
                  </h2>
                  <div id="solution" className="accordion-collapse collapse" data-bs-parent="#a1">
                    <div className="accordion-body">
                      <p>{data.solution}</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#result" aria-expanded="false" aria-controls="collapseThree">
                      Result
                    </button>
                  </h2>
                  <div id="result" className="accordion-collapse collapse" data-bs-parent="#a1">
                    <div className="accordion-body">
                      <p>{data.result}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ps-lg-5 pt-3 pt-lg-5">
              <img className="w-25" src="img/janney-logo.png" alt="janney-logo" />
              <h6>“We really wanted to simplify access for our users and essentially create one-stop-shopping.”</h6>
              <p>{data.we_really}</p>
              <a href="/path/to/case-study.pdf" className="req-demo mt-3" target="_blank" rel="noopener noreferrer">Download Case Study</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuickssTab;
