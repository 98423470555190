import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ZenTalksTemplate = () => {
  const { talkId } = useParams();
  const [zenTalk, setZenTalk] = useState(null);

  useEffect(() => {
    const fetchZenTalk = async () => {
      try {
        const response = await axios.get(`https://dev-zenoptics-react-2024.pantheonsite.io/wp-json/wp/v2/posts?_embed&categories=5`);
        if (response.data && response.data.length > 0) {
          // Assuming the ZenTalks content is the first item in the response array
          const zenTalkContent = response.data[0];
          setZenTalk(zenTalkContent);
        }
      } catch (error) {
        console.error('Error fetching ZenTalk:', error);
      }
    };

    fetchZenTalk();
  }, []);

  return (
    <div>
      {/* ZenTalks banner section */}
      <section className="zen-talk-ban bg-1e305f">
        <div className="container position-relative zi-9">
          <div className="row justify-content-center">
            <div className="col-xl-11">
              <div className="row align-items-center">
                <div className="col-xl-9 col-md-7 text-white">
                  {zenTalk && (
                    <>
                      <h1>{zenTalk.title.rendered}</h1>
                      <p dangerouslySetInnerHTML={{ __html: zenTalk.excerpt.rendered }}></p>
                    </>
                  )}
                </div>
                <div className="col-xl-3 col-md-5 text-center">
                  <div className="">
                    {zenTalk && zenTalk._embedded && zenTalk._embedded['wp:featuredmedia'] && (
                      <img src={zenTalk._embedded['wp:featuredmedia'][0].source_url} alt="claudia" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Add more sections or content here */}
    </div>
  );
};

export default ZenTalksTemplate;
