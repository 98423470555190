import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function BlogInner() {
  const location = useLocation();
  const slug = location.pathname.split('/').filter(Boolean).pop() || 'default-slug';
  console.log("Current Slug:", slug);

  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`https://dev-zenoptics-react-2024.pantheonsite.io/wp-json/wp/v2/posts?slug=${slug}&_embed`);
        const data = await response.json();

        console.log("API Response:", data);

        if (data.length > 0) {
          setPost(data[0]);
        } else {
          setError('Post not found');
        }
      } catch (error) {
        setError('Error fetching data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!post) {
    return <div>No post found</div>;
  }

  const title = post.title ? post.title.rendered : 'Untitled';
  const content = post.content ? post.content.rendered : '';
  const featuredImage = post._embedded && post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0].source_url ? post._embedded['wp:featuredmedia'][0].source_url : 'https://www.zenoptics.com/wp-content/uploads/2024/01/cropped-Favicon-1-96x96-1.png';
  const readingTime = '2 minutes';  // You can calculate this based on content length if needed

  return (
    <article>
      <section className="bg-1e305f position-relative blog-in-ban text-white">
        <i className="shape-1"></i>
        <div className="container container--2 position-relative zi-9">
          <div className="row">
            <div className="col-lg-7">
              <nav aria-label="breadcrumb" className="my-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="https://www.zenoptics.com/resources">Resources</a></li>
                  <li className="breadcrumb-item"><a href="https://www.zenoptics.com/blog">Blog</a></li>
                  <li className="breadcrumb-item active" aria-current="page">{title}</li>
                </ol>
              </nav>
              <h1 itemprop="headline">{title}</h1>
              <p className="reading-time">Reading time: <b>{readingTime}</b></p>
            </div>
          </div>
        </div>
      </section>
      <div className="blog-main-img" itemprop="image" role="img" aria-label={title} style={{backgroundImage: `url(${featuredImage})`}}></div>
      <section className="blog-content">
        <div className="container container--2 position-relative zi-9">
          <div className="row">
            <div className="col-lg-9 contents" itemprop="articleBody" dangerouslySetInnerHTML={{ __html: content }}>
            </div>
            <div className="col-lg-3 authr">
              <div className="author-image"> <img itemprop="image" src="https://www.zenoptics.com/wp-content/uploads/2024/01/cropped-Favicon-1-96x96-1.png" alt="" /></div>
              <h5>About The Author</h5>
              <p><span itemprop="author">ZenOptics</span> helps organizations drive increased value from their analytics assets by improving the ability to discover information, trust it, and ultimately use it for improving decision confidence. Through our integrated platform, organizations can provide business users with a centralized portal to streamline the searchability, access, and use of analytics from across the entire ecosystem of tools and applications.</p>
              <div className="touchs">
                <div className="soc"> 
                  <a href="https://www.linkedin.com/company/zenoptics/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-linkedin-in"></i></a> 
                  <a href="https://twitter.com/ZENOPTICS" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-x-twitter"></i></a> 
                  <a href="mailto:info@zenoptics.com" target="_blank" rel="noopener noreferrer"><i className="fa-solid fa-envelope"></i></a>
                </div>
                <div className="gint"> 
                  <span>Get In Touch</span> 
                  <a href="mailto:info@zenoptics.com" target="_blank" rel="noopener noreferrer">Send Email<i className="fa-solid fa-angle-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  );
}

export default BlogInner;
