import React, { useState, useEffect } from 'react';
import TeamsSection from '../components/teamsSection';

const About = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [dataUpdated, setDataUpdated] = useState(false); // State variable to track data updates

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://dev-zenoptics-react-2024.pantheonsite.io/wp-json/wp/v2/about?acf_format=standard&_fields=id,title,acf');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result[0]);
                setDataUpdated(true); // Set dataUpdated to true when new data is fetched
            } catch (err) {
                setError(err.message);
            }
        };

        fetchData();

        const intervalId = setInterval(fetchData, 1000); // Fetch data every 10 seconds

        // Cleanup interval on component unmount
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!data) {
        return <div>Loading...</div>;
    }

    const acf = data.acf;

    return (
        <>
          <section className="about bg-1e305f">
            <i className="shape-1"></i>
            <i className="shape-2"></i>
            <div className="container-lg position-relative">
                <div className="row">
                    <div className="col-md-6 pe-lg-5 text-white">
                        <h1>{acf.banner_title}</h1>
                        <p dangerouslySetInnerHTML={{ __html: acf.banner_desc }}></p>
						
                    </div>
                    <div className="col-md-6">
                        <div className="overflow-hidden br-5">
                           
							 {acf.banner_image && <img src={acf.banner_image.url} alt={acf.banner_image.title} />}
							
                        </div>
                    </div>
                </div>
            </div>
        </section>
		
		<section className="mission-ui">
            <div className="container-lg position-relative">
                <div className="row align-items-center">
                    <div className="col-md-6">
						{acf.mission_image && <img src={acf.mission_image.url} alt={acf.mission_image.title} />}
                    </div>
                    <div className="col-md-6 ps-lg-5">
                        <h2>{acf.mission_title}</h2>
                        <p dangerouslySetInnerHTML={{ __html: acf.mission_desc }}></p>
                    
                    </div>
                </div>
            </div>
        </section>
		
		
		
		 <section className="bg-f5f5f5 c__v">
            <div className="container-lg position-relative">
                <h3>{acf.culture_title}</h3>
                    <p dangerouslySetInnerHTML={{ __html: acf.culture_desc }}></p>
                <div className="row g-0 justify-content-center text-center">
                    <div className="col-md-3">
                        {acf.culture_photo1 && <img src={acf.culture_photo1.url} alt={acf.culture_photo1.title} />}
                    </div>
                    <div className="col-md-3">
                        {acf.culture_photo2 && <img src={acf.culture_photo2.url} alt={acf.culture_photo2.title} />}
                    </div>
                    <div className="col-md-3">
                         {acf.culture_photo3 && <img src={acf.culture_photo3.url} alt={acf.culture_photo3.title} />}
                    </div>
                    <div className="col-md-3">
                         {acf.culture_photo4 && <img src={acf.culture_photo4.url} alt={acf.culture_photo4.title} />}
                    </div>
                </div>
            </div>
        </section>
            <TeamsSection dataUpdated={dataUpdated} setDataUpdated={setDataUpdated} />
        </>
    );
};

export default About;
