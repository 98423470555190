import React, { useState, useEffect } from 'react';

const TeamsSection = () => {
    const [leadershipTeam, setLeadershipTeam] = useState([]);
    const [board, setBoard] = useState([]);
    const [advisors, setAdvisors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [dataUpdated, setDataUpdated] = useState(false); // State variable to track data updates

    const fetchData = async () => {
        try {
            const leadershipResponse = await fetch('https://dev-zenoptics-react-2024.pantheonsite.io/wp-json/wp/v2/leadershipteam?acf_format=standard&_fields=id,title,acf');
            const boardResponse = await fetch('https://dev-zenoptics-react-2024.pantheonsite.io/wp-json/wp/v2/boards?acf_format=standard&_fields=id,title,acf');
            const advisorsResponse = await fetch('https://dev-zenoptics-react-2024.pantheonsite.io/wp-json/wp/v2/advisors?acf_format=standard&_fields=id,title,acf');

            if (!leadershipResponse.ok || !boardResponse.ok || !advisorsResponse.ok) {
                throw new Error('Failed to fetch data');
            }

            const leadershipData = await leadershipResponse.json();
            const boardData = await boardResponse.json();
            const advisorsData = await advisorsResponse.json();

            setLeadershipTeam(leadershipData);
            setBoard(boardData);
            setAdvisors(advisorsData);
            setLoading(false);
            setDataUpdated(true); // Set dataUpdated to true when data is updated
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();

        const interval = setInterval(fetchData, 5000); // Fetch data every 5 seconds

        return () => clearInterval(interval);
    }, [dataUpdated]); // Trigger useEffect when dataUpdated changes

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <section className="teams">
            <div className="container-lg position-relative">
                <div className="text-center">
                    <h3>Leadership Team</h3>
                </div>
                <div className="row justify-content-center">
                    <div className="col-xl-10">
                        <div className="row">
                            {leadershipTeam.map(member => (
                                <div className="col-lg-4 col-sm-6 mb-3" key={member.id}>
                                    {member.acf && member.acf.leadershipimage && <img className="w-100" src={member.acf.leadershipimage.url} alt={member.title.rendered} />}
                                    <h5>{member.title.rendered}</h5>
                                    <p>{member.acf && member.acf.position}</p>
                                    <a className="lin-in" href={member.acf && member.acf.linkedin} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-linkedin-in"></i></a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="ui-pt-res-70 text-center">
                    <h3>Our Board</h3>
                </div>
                <div className="row justify-content-center">
                    <div className="col-xl-10">
                        <div className="row">
                            {board.map(member => (
                                <div className="col-lg-4 col-sm-6 mb-3" key={member.id}>
                                    {member.acf && member.acf.boardsimage && <img className="w-100" src={member.acf.boardsimage.url} alt={member.title.rendered} />}
                                    <h5>{member.title.rendered}</h5>
                                    <p>{member.acf && member.acf.position}</p>
                                    <a className="lin-in" href={member.acf && member.acf.linkedin} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-linkedin-in"></i></a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="ui-pt-res-70 text-center">
                    <h3>Advisors</h3>
                </div>
                <div className="row justify-content-center">
                    <div className="col-xl-10">
                        <div className="row">
                            {advisors.map(member => (
                                <div className="col-lg-3 col-sm-6 mb-3" key={member.id}>
                                    {member.acf && member.acf.advisorsimg && <img src={member.acf.advisorsimg.url} alt={member.title.rendered} />}
                                    <h5>{member.title.rendered}</h5>
                                    <p>{member.acf && member.acf.position}</p>
                                    <a className="lin-in" href={member.acf && member.acf.linkedin} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-linkedin-in"></i></a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TeamsSection;
