import React, { useState, useEffect } from 'react';
import Connectorsplatform from '../components/connectorsplatform';

const Platform = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://dev-zenoptics-react-2024.pantheonsite.io/wp-json/wp/v2/platform?acf_format=standard&_fields=id,title,acf');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result[0]);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchData();

        const intervalId = setInterval(fetchData, 10000); // Fetch data every 10 seconds

        // Cleanup interval on component unmount
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!data) {
        return <div>Loading...</div>;
    }

    const acf = data.acf;

    return (
        <>
            <section className="short-ban platform-ban-v1 bg-1e305f">
                <i className="shape-1"></i>
                <i className="ssp"></i>
                <div className="container position-relative zi-9">
                    <div className="row">
                        <div className="col-lg-6 text-white mb-4">
                            <h1>{acf.banner_title}</h1>
                            <p>{acf.banner_desc || ''}</p>
                            <a className="req-demo" href="demo.html">Request Demo</a>
                        </div>
                    </div>
                </div>
                <div className="hub-ring text-end ">
                    {acf.banner_image && <img src={acf.banner_image.url} alt={acf.banner_image.title} />}
                </div>
            </section>

            <div className="bi-interfaces">
                <div className="container-lg">
                    <div className="row">
                        <div className="col-lg-6">
                            <h2>{acf.analytics_title}</h2>
                            <p className="mb-lg-5 mb-3" dangerouslySetInnerHTML={{ __html: acf.foundation_desc }}></p>
                            <div className="row justify-content-center">
                                <div className="col-xxl-9">
                                    {acf.analytics_image && <img src={acf.analytics_image.url} alt={acf.analytics_image.title} />}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="ps-lg-4 pt-4 mt-lg-4">
                                <div className="mb-25">
                                    <p dangerouslySetInnerHTML={{ __html: acf.portal }}></p>
                                </div>
                                <div className="mb-25">
                                    <p dangerouslySetInnerHTML={{ __html: acf.catalog }}></p>
                                </div>
                                <div className="mb-25">
                                    <p dangerouslySetInnerHTML={{ __html: acf.collaboration }}></p>
                                </div>
                                <div className="mb-25">
                                    <p dangerouslySetInnerHTML={{ __html: acf.automation }}></p>
                                </div>
                                <div className="mb-25">
                                    <p dangerouslySetInnerHTML={{ __html: acf.governance }}></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="designed-for">
                        <h3>{acf.designed}</h3>
                        <div className="row">
                            <div className="col-md-6 col-lg-4 col-xl-3 pr-sets-11">
                                <img src={acf.knowledge_image.url} alt="Knowledge workers" />
                                <h6 dangerouslySetInnerHTML={{ __html: acf.knowledge_workers }}></h6>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-3 pr-sets-11">
                                <img src={acf.information_image.url} alt="Information Stewards" />
                                <h6 dangerouslySetInnerHTML={{ __html: acf.information }}></h6>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-3 pr-sets-11">
                                <img src={acf.executive_image.url} alt="Executive & Management teams" />
                                <h6 dangerouslySetInnerHTML={{ __html: acf.executive }}></h6>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-3 pr-sets-11">
                                <img src={acf.data_image.url} alt="Data & Analytics Teams" />
                                <h6 dangerouslySetInnerHTML={{ __html: acf.data }}></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bi-features bg-1e305f text-white">
                <i className="shape-1"></i>
                <i className="shape-2"></i>
                <div className="container-lg position-relative">
                    <div className="outer-contain">
                        <div className={`row`}>
                            <div className="col-xl-6 pt-lg-5">
                                <h3 dangerouslySetInnerHTML={{ __html: acf.features_title }}></h3>
                                <h4 dangerouslySetInnerHTML={{ __html: acf.features_title_copy1 }}></h4>
                                <h6 dangerouslySetInnerHTML={{ __html: acf.features_title_copy2 }}></h6>
                                <span dangerouslySetInnerHTML={{ __html: acf.features_desc }}></span>
                            </div>
                            <div className="col-xl-6">
                                <div className="xss-img">
                                    {acf.features_image?.url && <img src={acf.features_image.url} alt={acf.features_image.title} />}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="outer-contain">
                        <div className={`row row-rev`}>
                            <div className="col-xl-6">
                                <div className="xss-img-1">
                                    {acf.intuitive_image?.url && <img src={acf.intuitive_image.url} alt={acf.intuitive_image.title} />}
                                </div>
                            </div>
                            <div className="col-xl-6 pt-lg-5">
                                <div className="xss-img-1-cnt">
                                    <h3 dangerouslySetInnerHTML={{ __html: acf.features_title_copy3 }}></h3>
                                    <h4 dangerouslySetInnerHTML={{ __html: acf.features_title_copy4 }}></h4>
                                    <span dangerouslySetInnerHTML={{ __html: acf.intuitive }}></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="outer-contain">
                        <div className={`row`}>
                            <div className="col-xl-6 pe-lg-5">
                                <h3 dangerouslySetInnerHTML={{ __html: acf.features_title_copy5 }}></h3>
                                <h4 dangerouslySetInnerHTML={{ __html: acf.features_title_copy6 }}></h4>
                                <span dangerouslySetInnerHTML={{ __html: acf.intelligent }}></span>
                            </div>
                            <div className="col-xl-6">
                                <div className="xss-img">
                                    {acf.analytics_governance_image?.url && <img src={acf.analytics_governance_image.url} alt={acf.analytics_governance_image.title} />}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="outer-contain">
                        <div className={`row`}>
                            <div className="col-xl-6">
                                <div className="xss-img-1">
                                    {acf.intelligent_image?.url && <img src={acf.intelligent_image.url} alt={acf.intelligent_image.title} />}
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="xss-img-1-cnt">
                                    <h3 dangerouslySetInnerHTML={{ __html: acf.features_title_copy7 }}></h3>
                                    <h4 dangerouslySetInnerHTML={{ __html: acf.features_title_copy8 }}></h4>
                                    <span dangerouslySetInnerHTML={{ __html: acf.intelligent }}></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Connectorsplatform/>
        </>
    );
};

export default Platform;
