import React, { useState, useEffect } from 'react';
import PlatformFeatures from '../components/PlatformFeatures';
import QuickssTab from '../components/QuickssTab';
import Connectors from '../components/connectors';

const Overview = () => {
  const [data, setData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://dev-zenoptics-react-2024.pantheonsite.io/wp-json/wp/v2/overview?acf_format=standard');
        const responseData = await response.json();
        setData(responseData[0]?.acf || null);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Fetch data initially
    fetchData();

    // Fetch data every 10 seconds
    const intervalId = setInterval(fetchData, 10000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex === 0 ? 4 : prevIndex - 1));
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex === 4 ? 0 : prevIndex + 1));
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  const { the_essential, essential_image, metadata, security_title, security_image, security_list } = data;

  return (
    <>
      <section className="short-ban overview bg-1e305f">
        <i className="shape-1"></i>
        <i className="ssp"></i>
        <div className="container position-relative zi-9">
          <div className="row">
            <div className="col-lg-6 text-white mb-4">
              <p>{data.banner_title}</p>
              <h1 dangerouslySetInnerHTML={{ __html: data.banner_desc }}></h1>
              <a className="req-demo mt-3 me-2" href={data.demo_link}>Get Demo</a>
              <a className="gd mt-3" href={data.contact_link}>Contact Us</a>
            </div>
            <div className="col-lg-6">
              <div className="short-ban-round">
                {data.banner_image && <img src={data.banner_image.url} alt="Use Cases" />}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="text-center overview-sec-2">
        <div className="container position-relative zi-9">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <p dangerouslySetInnerHTML={{ __html: data.data_and_analytics }}></p>
            </div>
          </div>
        </div>
      </div>

      <section className="scaled-consumable">
        <div className="container position-relative zi-9">
          <div className="row align-items-center">
            <div className="col-lg-6">
              {data.analytics_image && <img src={data.analytics_image.url} alt="" />}
            </div>
            <div className="col-lg-6">
              <div className="c3-main">
                <div id="c3" className="carousel slide carousel-fade">
                  <div className="carousel-inner">
                    <div key="catalog" className={`carousel-item ${activeIndex === 0 ? 'active' : ''}`}>
                      <div className="loop-item-1">
                        <h3><a href={data.catalog_link}>{data.analytics_catalog_title}</a></h3>
                        <p>{data.analytics_catalog_desc}</p>
                        <ul className="list-1" dangerouslySetInnerHTML={{ __html: data.analytics_catalog_list }}></ul>
                        <a className="req-demo mt-3 me-2" href={data.catalog_link}>Get Demo</a>
                      </div>
                    </div>
                    <div key="governance" className={`carousel-item ${activeIndex === 1 ? 'active' : ''}`}>
                      <div className="loop-item-1">
                        <h3><a href={data.governance_link}>{data.analytics_governance_title}</a></h3>
                        <p>{data.governance_desc}</p>
                        <ul className="list-1" dangerouslySetInnerHTML={{ __html: data.governance_list }}></ul>
                        <a className="req-demo mt-3 me-2" href={data.governance_link}>Get Demo</a>
                      </div>
                    </div>
                    <div key="automations" className={`carousel-item ${activeIndex === 2 ? 'active' : ''}`}>
                      <div className="loop-item-1">
                        <h3><a href={data.automations_link}>{data.automations_title}</a></h3>
                        <p>{data.automations_desc}</p>
                        <ul className="list-1" dangerouslySetInnerHTML={{ __html: data.automations_list }}></ul>
                        <a className="req-demo mt-3 me-2" href={data.automations_link}>Get Demo</a>
                      </div>
                    </div>
                    <div key="workspace" className={`carousel-item ${activeIndex === 3 ? 'active' : ''}`}>
                      <div className="loop-item-1">
                        <h3><a href={data.analytics_link}>{data.analytics_workspace_title}</a></h3>
                        <p>{data.analytics_desc}</p>
                        <ul className="list-1" dangerouslySetInnerHTML={{ __html: data.analytics_list }}></ul>
                        <a className="req-demo mt-3 me-2" href={data.analytics_link}>Get Demo</a>
                      </div>
                    </div>
                    <div key="mining" className={`carousel-item ${activeIndex === 4 ? 'active' : ''}`}>
                      <div className="loop-item-1">
                        <h3><a href={data.mining_link}>{data.mining_title}</a></h3>
                        <p>{data.mining_desc}</p>
                        <ul className="list-1" dangerouslySetInnerHTML={{ __html: data.mining_list }}></ul>
                        <a className="req-demo mt-3 me-2" href={data.mining_link}>Get Demo</a>
                      </div>
                    </div>
                  </div>
                  <button className="carousel-control-prev" type="button" onClick={handlePrev}>
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button className="carousel-control-next" type="button" onClick={handleNext}>
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-f5f5f5 open-ece">
        <div className="container position-relative zi-9">
          <div className="row designed-for designed-for-open-ece">
            <div className="col-md-6 col-lg-4 col-xl-3 pr-sets-11">
              {data.flexible_image && <img src={data.flexible_image.url} alt={data.flexible_title} />}
              <h6 dangerouslySetInnerHTML={{ __html: data.flexible_title }}></h6>
              <p dangerouslySetInnerHTML={{ __html: data.flexible_description }}></p>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3 pr-sets-11">
              {data.fit_image && <img src={data.fit_image.url} alt={data.fit_image} />}
              <h6 dangerouslySetInnerHTML={{ __html: data.fit_design }}></h6>
              <p dangerouslySetInnerHTML={{ __html: data.fit_design_description }}></p>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3 pr-sets-11">
              {data.built_image && <img src={data.built_image.url} alt={data.built_image} />}
              <h6 dangerouslySetInnerHTML={{ __html: data.built_work }}></h6>
              <p dangerouslySetInnerHTML={{ __html: data.built_work_description }}></p>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3 pr-sets-11">
              {data.dashboards_image && <img src={data.dashboards_image.url} alt={data.dashboards} />}
              <h6 dangerouslySetInnerHTML={{ __html: data.dashboards }}></h6>
              <p dangerouslySetInnerHTML={{ __html: data.dashboards_description }}></p>
            </div>
          </div>
        </div>
      </section>

      <PlatformFeatures data={data} />
      <QuickssTab data={data} />

      <div className="bg-1e305f grade-enterprise position-relative overflow-hidden">
        <i className="shape-2"></i>
        <div className="container-lg container--1">
          <h4>{the_essential}</h4>
          <div className="row">
            <div className="col-md-6 col-xl-5 mb-4">
              <img src={essential_image.url} className="computer-security" alt="stock-market-data-on-tablet" />
            </div>
            <div className="col-md-6 col-xl-7 ps-lg-5">
              <ul className="list-media">
                <li dangerouslySetInnerHTML={{ __html: metadata }}></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Connectors />

      <div className="bg-1e305f grade-enterprise position-relative overflow-hidden">
        <i className="shape-2"></i>
        <div className="container-lg container--1">
          <h4>{security_title}</h4>
          <div className="row">
            <div className="col-md-6 col-xl-5 mb-4">
              <img src={security_image.url} className="computer-security" alt="computer-security-technology" />
            </div>
            <div className="col-md-6 col-xl-7 ps-lg-5">
              <ul className="list-key">
                <li dangerouslySetInnerHTML={{ __html: security_list }}></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
