import React, { useEffect } from 'react';

const Demo = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "2836830",
          formId: "cf5b88ec-31e8-4181-bd02-9691c8974085",
          target: '#hubspotFormContainerdemo', // Replace with your container ID or class
        });
      }
    });

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
  <section className="bg-ffb247 position-relative demo-ban">
    <i className="shape-1" />
    <i className="shape-2" />
    <div className="container container--1 position-relative zi-9">
      <div className="row">
        <div className="col-lg-6">
          <h1>Register for a Personalized Demo</h1>
          <p>
            Get a first-hand preview of the ZenOptics analytics hub with a
            personalized demo! Meet a member of our team online to discover how
            it empowers teams to discover, compose, and scale analytics assets.
          </p>
          <p>
            <a className="mail" href="mailto:info@zenoptics.com">
              <i className="fa-solid fa-envelope" />
              info@zenoptics.com
            </a>
          </p>
          <p>
            <a className="phone" href="tel:4084207906">
              <i className="fa-solid fa-phone-flip" />
              (408) <span>420-7906</span>
            </a>
          </p>
        </div>
        <div className="col-lg-6">
          <div className="contact-form">
            <h5>Personal Demo</h5>
			<div id="hubspotFormContainerdemo">
      
    </div> 
          </div>
        </div>
      </div>
    </div>
  </section>
  <div
    className="short-ban-cross"
    style={{ backgroundImage: "url(img/home-page-screenshots.webp)" }}
  >
    <i className="ssp" />
  </div>
</>

  );
};

export default Demo;