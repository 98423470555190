import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Home = () => {
    const [data, setData] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [featuredBlogs, setFeaturedBlogs] = useState([]);
    const [categories, setCategories] = useState([]);
    const apiBaseUrl = 'https://dev-zenoptics-react-2024.pantheonsite.io/wp-json/wp/v2/';


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://dev-zenoptics-react-2024.pantheonsite.io/wp-json/wp/v2/home?acf_format=standard&_fields=id,title,acf&_=${new Date().getTime()}`);
                const { acf } = response.data[0];
                setData(acf);
                setIsLoaded(true);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const fetchFeaturedBlogs = async () => {
            try {
                const response = await axios.get('https://dev-zenoptics-react-2024.pantheonsite.io/wp-json/wp/v2/posts?per_page=2&_embed');
                setFeaturedBlogs(response.data);
            } catch (error) {
                console.error('Error fetching featured blogs:', error);
            }
        };

        const fetchCategories = async () => {
            try {
              const response = await axios.get(`${apiBaseUrl}categories`);
              if (response.data && response.data.length > 0) {
                // Filter out duplicate categories, keeping only one "ZenTalks"
                const uniqueCategories = response.data.filter((category, index, self) =>
                  category.name !== 'ZenTalks' || self.findIndex(c => c.name === 'ZenTalks') === index
                );
                setCategories(uniqueCategories);
              }
            } catch (error) {
              console.error('Error fetching categories:', error);
            }
          };

        

        fetchData();
        fetchCategories();
        fetchFeaturedBlogs();

        // Fetch data every 5 seconds
        const interval = setInterval(fetchData, 5000);

        // Clear interval on component unmount
        return () => clearInterval(interval);
    }, []);

    // Function to find category name based on category ID
  const findCategoryName = (categoryIds) => {
    const categoryNames = [];
    if (categoryIds && categories.length > 0) {
      categoryIds.forEach(categoryId => {
        const category = categories.find(cat => cat.id === categoryId);
        if (category) {
          categoryNames.push(category.name);
        }
      });
    }
    return categoryNames.join(', ');
  };

    const {
        banner_image,
        banner_description,
        banner_title,
        contact_link,
        contact_text,
        value_title,
        value_description,
        value_image,
        unified_title,
        unified_image1,
        unified_desc1,
        unified_image2,
        unified_desc2,
        unified_image3,
        unified_desc3,
        organization_title,
        count_desc1,
        count_desc2,
        count_desc3,
        bi_title,
        bi_desc,
        bi_content,
        bi_image,
        bi_learntext,
        bi_learnlink,
        bi_demotext,
        bi_demolink,
        power_title,
        discover_title,
        discover_description,
        discover_image,
        discover_image_alt,
        compose_title,
        compose_description,
        compose_image,
        compose_image_alt,
        scale_title,
        scale_description,
        scale_image,
        scale_image_alt,
        userexperience_title,
        user_description,
        user_image,
        user_image_alt,
    } = data;

    if (isLoaded) {
        return (
            <div>
                <section className="home-banner platform-ban bg-1e305f" style={{ backgroundImage: `url(${banner_image?.url})` }}>
                    <i className="shape-1" />
                    <i className="ssp" />
                    <div className="container container--1 position-relative zi-9">
                        <div className="row">
                            <div className="col-lg-10 text-white">
                                <h1 dangerouslySetInnerHTML={{ __html: banner_title }}></h1>
                                <p dangerouslySetInnerHTML={{ __html: banner_description }}></p>
                                <a className="a-tags mt-3" href={contact_link}>{contact_text || 'Contact Us'}</a>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="home-sec-2 text-center ui-pt-res-70">
                    <div className="container-lg">
                        <h2 dangerouslySetInnerHTML={{ __html: value_title }}></h2>
                        <p dangerouslySetInnerHTML={{ __html: value_description }}></p>
                    </div>
                </div>

                <div className="home-sec-3 text-center">
                    <div className="container-lg container--1">
                        <img src={value_image?.url} alt={value_title} className="img-fluid" />
                    </div>
                </div>

                <div className="home-sec-4 text-center">
                    <div className="container-lg container--1">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <h2 dangerouslySetInnerHTML={{ __html: unified_title }}></h2>
                            </div>
                        </div>
                        <div className="row px-3">
                            <div className="col-md-4">
                                <img src={unified_image1?.url} alt={unified_title} className="img-fluid" />
                                <div dangerouslySetInnerHTML={{ __html: unified_desc1 }}/>
                            </div>
                            <div className="col-md-4">
                                <img src={unified_image2?.url} alt={unified_title} className="img-fluid" />
                                <div dangerouslySetInnerHTML={{ __html: unified_desc2 }}/>
                            </div>
                            <div className="col-md-4">
                                <img src={unified_image3?.url} alt={unified_title} className="img-fluid" />
                                <div dangerouslySetInnerHTML={{ __html: unified_desc3 }}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="home-sec-5 text-center">
                    <div className="container-lg container--1">
                        <div className="home-sec-5-in">
                            <h2 dangerouslySetInnerHTML={{ __html: organization_title }}></h2>
                            <div className="row">
                                <div className="col-md-4">
                                    <p dangerouslySetInnerHTML={{ __html: count_desc1 }}></p>
                                </div>
                                <div className="col-md-4">
                                    <p dangerouslySetInnerHTML={{ __html: count_desc2 }}></p>
                                </div>
                                <div className="col-md-4">
                                    <p dangerouslySetInnerHTML={{ __html: count_desc3 }}></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="home-sec-6 bg-1e305f text-white">
                    <i className="shape-1"></i>
                    <div className="container-lg">
                        <div className="text-center tf-set">
                            <h3>{bi_title}</h3>
                            <p>{bi_desc}</p>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <img src={bi_image?.url} alt="" className="img-fluid" />
                            </div>
                            <div className="col-md-6">
                                <div className="dsl-left">
                                    <h5 dangerouslySetInnerHTML={{ __html: bi_content }}></h5>
                                    <p>{bi_learntext}</p>
                                    <p>{bi_demotext}</p>
                                    <a className="a-tags mt-3 me-2" href={bi_learnlink}>Learn More</a>
                                    <a className="gd mt-3" href={bi_demolink}>Get Demo</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="home-sec-7">
                    <div className="container-lg container--1">
                        <div className="power-simple">
                            <h2 className="text-center mb-5" dangerouslySetInnerHTML={{ __html: power_title }}></h2>
                            <nav>
                                <div className="nav my-nav-tabs max-tab-4" id="nav-tab" role="tablist">
                                    <button className="nav-link active" id="nav-discover-tab" data-bs-toggle="tab" data-bs-target="#nav-discover" type="button" role="tab" aria-controls="nav-discover" aria-selected="true">
                                        <h5 dangerouslySetInnerHTML={{ __html: discover_title }}></h5>
                                    </button>
                                    <button className="nav-link" id="nav-compose-tab" data-bs-toggle="tab" data-bs-target="#nav-compose" type="button" role="tab" aria-controls="nav-compose" aria-selected="false">{compose_title}</button>
                                    <button className="nav-link" id="nav-scale-tab" data-bs-toggle="tab" data-bs-target="#nav-scale" type="button" role="tab" aria-controls="nav-scale" aria-selected="false">{scale_title}</button>
                                    <button className="nav-link" id="nav-experience-tab" data-bs-toggle="tab" data-bs-target="#nav-experience" type="button" role="tab" aria-controls="nav-experience" aria-selected="false">{userexperience_title}</button>
                                </div>
                            </nav>
                            <div className="tab-content my-nav-tabs-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-discover" role="tabpanel" aria-labelledby="nav-discover-tab" tabIndex="0">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <h5 dangerouslySetInnerHTML={{ __html: discover_description }}></h5>
                                        </div>
                                        <div className="col-lg-7">
                                            <img src={discover_image?.url} alt={discover_image_alt} className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-compose" role="tabpanel" aria-labelledby="nav-compose-tab" tabIndex="0">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <h5 dangerouslySetInnerHTML={{ __html: compose_description }}></h5>
                                        </div>
                                        <div className="col-lg-7">
                                            <img src={compose_image?.url} alt={compose_image_alt} className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-scale" role="tabpanel" aria-labelledby="nav-scale-tab" tabIndex="0">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <h5 dangerouslySetInnerHTML={{ __html: scale_description }}></h5>
                                        </div>
                                        <div className="col-lg-7">
                                            <img src={scale_image?.url} alt={scale_image_alt} className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-experience" role="tabpanel" aria-labelledby="nav-experience-tab" tabIndex="0">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <h5 dangerouslySetInnerHTML={{ __html: user_description }}></h5>
                                        </div>
                                        <div className="col-lg-7">
                                            <img src={user_image?.url} alt={user_image_alt} className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-f5f5f5 home-feat-blog">
            <div className="container-lg container--2">
                <div className="text-center">
                    <h3>Featured Blogs</h3>
                </div>
                <div className="row">
                    {featuredBlogs.map(post => (
                        <div key={post.id} className="col-md-6 mb-3">
                            <article className="ddf-box" data-category={findCategoryName(post.categories)}>
                                <a href={post.link} className="res-a"></a>
                                <div className="ddf-box-img">
                                    <div className="ddf-box-img-in" style={{ backgroundImage: `url(${post._embedded['wp:featuredmedia'][0].source_url})` }}></div>
                                </div>
                                <div className="ddf-box-cnt">
                                    <span className="category">Category: <a href={findCategoryName(post.categories)} className="term-link">{findCategoryName(post.categories)}</a></span>
                                    <span className="author">Author: <a href="author.html" rel="author" className="author-link">{post.author}</a></span>
                                    <h5>{post.title.rendered}</h5>
                                </div>
                                <span className="span-rm-blog">Read Blog</span>
                            </article>
                        </div>
                    ))}
                </div>
                <div className="text-center">
                    <a href="/resources" className="req-demo mt-3 mt-md-5">More Resources</a>
                </div>
            </div>
        </div>
            </div>
        );
    } else {
        return <div>Loading...</div>;
    }
};

export default Home;
