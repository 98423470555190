import React from 'react';

const PlatformFeatures = ({ data }) => {
  if (!data) {
    return <div>Loading platform features...</div>;
  }

  const {
    main_platform_features_title_1,
    main_platform_features_title_2,
    main_platform_features_title_3,
    main_platform_features_title_4,
    all_analytics,
    ai_video,
    ai_search,
    all_video,
    package_analytic,
    administrator_video,
    administrator,
    main_title
  } = data;

  return (
    <div className="overview-platform-feature">
      <div className="container-lg container--1">
        <div className="power-simple">
          <h2 className="text-center mb-md-5 mb-3">{main_title}</h2>

          <nav>
            <div className="nav my-nav-tabs max-tab-4 overviewtabs" id="nav-tab" role="tablist">
              <button className="nav-link active" id="nav-discover-tab" data-bs-toggle="tab" data-bs-target="#nav-discover" type="button" role="tab" aria-controls="nav-discover" aria-selected="true">{main_platform_features_title_1}</button>
              <button className="nav-link" id="nav-compose-tab" data-bs-toggle="tab" data-bs-target="#nav-compose" type="button" role="tab" aria-controls="nav-compose" aria-selected="false" tabindex="-1">{main_platform_features_title_2}</button>
              <button className="nav-link" id="nav-scale-tab" data-bs-toggle="tab" data-bs-target="#nav-scale" type="button" role="tab" aria-controls="nav-scale" aria-selected="false" tabindex="-1">{main_platform_features_title_3}</button>
              <button className="nav-link" id="nav-experience-tab" data-bs-toggle="tab" data-bs-target="#nav-experience" type="button" role="tab" aria-controls="nav-experience" aria-selected="false" tabindex="-1">{main_platform_features_title_4}</button>
            </div>
          </nav>

          <div className="tab-content my-nav-tabs-content" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-discover" role="tabpanel" aria-labelledby="nav-discover-tab" tabindex="0">
              <div className="row">
                <div className="col-lg-5">
                  <h5 dangerouslySetInnerHTML={{ __html: all_analytics }}></h5>
                </div>
                <div className="col-lg-7">
                  <video playsinline="" className="zen-video" poster="" controls="" loop="true" autoPlay="autoplay" muted="muted" style={{ pointerEvents: 'none' }}>
                    <source src={ai_video} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>

            <div className="tab-pane fade" id="nav-compose" role="tabpanel" aria-labelledby="nav-compose-tab" tabindex="0">
              <div className="row">
                <div className="col-lg-5">
                <h5 dangerouslySetInnerHTML={{ __html: ai_search }}></h5>
                </div>
                <div className="col-lg-7">
                  <video playsinline="" className="zen-video" poster="" controls="" loop="true" autoPlay="autoplay" muted="muted" style={{ pointerEvents: 'none' }}>
                    <source src={all_video} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>

            <div className="tab-pane fade" id="nav-scale" role="tabpanel" aria-labelledby="nav-contact-tab" tabindex="0">
              <div className="row">
                <div className="col-lg-5">
                <h5 dangerouslySetInnerHTML={{ __html: package_analytic }}></h5>
                </div>
                <div className="col-lg-7">
                  <video playsinline="" className="zen-video" poster="" controls="" loop="true" autoPlay="autoplay" muted="muted" style={{ pointerEvents: 'none' }}>
                    <source src={ai_video} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>

            <div className="tab-pane fade" id="nav-experience" role="tabpanel" aria-labelledby="nav-experience-tab" tabindex="0">
              <div className="row">
                <div className="col-lg-5">
                <h5 dangerouslySetInnerHTML={{ __html: administrator }}></h5>
                  
                </div>
                <div className="col-lg-7">
                  <video playsinline="" className="zen-video" poster="" controls="" loop="true" autoPlay="autoplay" muted="muted" style={{ pointerEvents: 'none' }}>
                    <source src={administrator_video} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



  );
};

export default PlatformFeatures;
