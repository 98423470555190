import React, { useState, useEffect } from 'react';

const Connectors = () => {
    const [biTools, setBiTools] = useState([]);
    const [productivityApps, setProductivityApps] = useState([]);
    const [businessApps, setBusinessApps] = useState([]);
    const [sharedStorage, setSharedStorage] = useState([]);

    useEffect(() => {
        const fetchData = async (url, setState) => {
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log('Fetched data:', data); // Debugging line
                setState(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData('https://dev-zenoptics-react-2024.pantheonsite.io/wp-json/wp/v2/bitools?acf_format=standard', setBiTools);
        fetchData('https://dev-zenoptics-react-2024.pantheonsite.io/wp-json/wp/v2/productivity?acf_format=standard', setProductivityApps);
        fetchData('https://dev-zenoptics-react-2024.pantheonsite.io/wp-json/wp/v2/businessapps?acf_format=standard', setBusinessApps);
        fetchData('https://dev-zenoptics-react-2024.pantheonsite.io/wp-json/wp/v2/sharedstorage?acf_format=standard', setSharedStorage);
    }, []);

    const renderConnectors = (connectors) => {
        return connectors.map((connector, index) => {
            console.log('connector:', connector); // Debugging line
            const imageUrl = connector.acf && connector.acf['ecosystem-image'] && connector.acf['ecosystem-image'].url ? connector.acf['ecosystem-image'].url : '';
            console.log('imageUrl:', imageUrl); // Debugging line
            return (
                <div key={index} className="col-md-4 col-sm-6 mb-2 mb-md-4">
                    <div className="boxx">
                        <img src={imageUrl} alt={connector.title.rendered} />
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="zenOptics-connectors">
            <div className="container-lg container--1">
                <div className="zenOptics-connectors-in">
                    <div className="text-center">
                        <h3>Connecting the Ecosystem</h3>
                        <h5>*For a complete list of connected tools and apps, please <a href="" className="c-dd4a78">Contact Us!</a></h5>
                    </div>
                    <nav>
                        <div className="nav my-nav-tabs max-tab-4" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-bat-tab" data-bs-toggle="tab" data-bs-target="#nav-bat" type="button" role="tab" aria-controls="nav-bat" aria-selected="true">BI & Analytics Tools</button>
                            <button className="nav-link" id="nav-pa-tab" data-bs-toggle="tab" data-bs-target="#nav-pa" type="button" role="tab" aria-controls="nav-pa" aria-selected="false" tabIndex="-1">Productivity Apps</button>
                            <button className="nav-link" id="nav-ba-tab" data-bs-toggle="tab" data-bs-target="#nav-ba" type="button" role="tab" aria-controls="nav-ba" aria-selected="false" tabIndex="-1">Business Apps</button>
                            <button className="nav-link" id="nav-ss-tab" data-bs-toggle="tab" data-bs-target="#nav-ss" type="button" role="tab" aria-controls="nav-ss" aria-selected="false" tabIndex="-1">Shared Storage</button>
                        </div>
                    </nav>
                    <div className="tab-content my-nav-tabs-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-bat" role="tabpanel" aria-labelledby="nav-bat-tab" tabIndex="0">
                            <div className="row">
                                {renderConnectors(biTools)}
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-pa" role="tabpanel" aria-labelledby="nav-pa-tab" tabIndex="0">
                            <div className="row">
                                {renderConnectors(productivityApps)}
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-ba" role="tabpanel" aria-labelledby="nav-ba-tab" tabIndex="0">
                            <div className="row">
                                {renderConnectors(businessApps)}
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-ss" role="tabpanel" aria-labelledby="nav-ss-tab" tabIndex="0">
                            <div className="row">
                                {renderConnectors(sharedStorage)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <a href="contact.html" className="req-demo mt-3 mt-md-5">Contact Us</a>
                </div>
            </div>
        </div>
    );
};

export default Connectors;
