import React, { useState, useEffect } from 'react';


const Usecases = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://dev-zenoptics-react-2024.pantheonsite.io/wp-json/wp/v2/use-cases?acf_format=standard&_fields=id,title,acf');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result[0]);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchData();

        const intervalId = setInterval(fetchData, 10000); // Fetch data every 10 seconds

        // Cleanup interval on component unmount
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!data) {
        return <div>Loading...</div>;
    }

    const acf = data.acf;

    return (
        <>
           
		<section className="short-ban bg-1e305f">
        <i className="shape-1"></i>
        <i className="ssp"></i>
        <div className="container position-relative zi-9">
            <div className="row">
                <div className="col-lg-6 text-white mb-4">
                    <h1>{acf.banner_title}</h1>
                    <p>{acf.banner_desc || ''}</p>
                    <a className="req-demo" href="demo.html">Request Demo</a>
                </div>
                <div className="col-lg-6">
                    <div className="short-ban-round">
                         {acf.banner_image && <img src={acf.banner_image.url} alt={acf.banner_image.title} />}
                    </div>
                </div>
            </div>
        </div>
    </section>	
	
	
	
	
        <section className="right-left-cnt">
            <div className="container-lg">
                <div className="row">
                    <div className="col-md-6 ui-mb-res-50 pe-lg-5">
                        {acf.unified_image && <img src={acf.unified_image.url} alt={acf.unified_image.title} />}
                        <h3>{acf.unified_title}</h3>
                        <p  dangerouslySetInnerHTML={{ __html: acf.unified_desc }}></p>
                        <h5>{acf.zenoptics_addresses} </h5>
                        <span dangerouslySetInnerHTML={{ __html: acf.providing_list }}></span>
                    </div>

                    <div className="col-md-6 ui-mb-res-50 ps-lg-5">
                        {acf.tool_image && <img src={acf.tool_image.url} alt={acf.tool_image.title} />}
                        <h3>{acf.tool_title}</h3>
                        <p  dangerouslySetInnerHTML={{ __html: acf.tool_desc }}></p>
                        <h5>{acf.tool_zen} </h5>
                        <span dangerouslySetInnerHTML={{ __html: acf.tool_list }}></span>
                    </div>
                    <div className="col-md-6 ui-mb-res-50 pe-lg-5">
                       {acf.analytics_image && <img src={acf.analytics_image.url} alt={acf.analytics_image.title} />}
                        <h3>{acf.analytics_title}</h3>
                        <p  dangerouslySetInnerHTML={{ __html: acf.analytics_desc }}></p>
                        <h5>{acf.analytics_sub} </h5>
                        <span dangerouslySetInnerHTML={{ __html: acf.analytics_list }}></span>
                    </div>
                    <div className="col-md-6 ui-mb-res-50 ps-lg-5">
                        {acf.governance_image && <img src={acf.governance_image.url} alt={acf.governance_image.title} />}
                        <h3>{acf.governance_title}</h3>
                        <p  dangerouslySetInnerHTML={{ __html: acf.governance_desc }}></p>
                        <h5>{acf.governance_name} </h5>
                        <span dangerouslySetInnerHTML={{ __html: acf.governance_list }}></span>
                      
                    </div>
                    <div className="col-md-6 ui-mb-res-50 pe-lg-5">

                    {acf.mergers_image && <img src={acf.mergers_image.url} alt={acf.mergers_image.title} />}
                        <h3>{acf.mergers_title}</h3>
                        <p  dangerouslySetInnerHTML={{ __html: acf.mergers_desc }}></p>
                        <h5>{acf.mergers_sub} </h5>
                        <span dangerouslySetInnerHTML={{ __html: acf.mergers_list }}></span>
                    </div>
                    <div className="col-md-6 ui-mb-res-50 ps-lg-5">

                    {acf.reconciliation_image && <img src={acf.reconciliation_image.url} alt={acf.reconciliation_image.title} />}
                        <h3>{acf.reconciliation_title}</h3>
                        <p  dangerouslySetInnerHTML={{ __html: acf.reconciliation_desc }}></p>
                        <h5>{acf.reconciliation_sub} </h5>
                        <span dangerouslySetInnerHTML={{ __html: acf.reconciliation_list }}></span>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
};

export default Usecases;
