import React from 'react';
import Home from './pages/Home';
import Demo from './pages/demo';
import Overview from './pages/overview';
import Platform from './pages/platform';
import Resources from './pages/resources';
import Usecases from './pages/usecases';
import Header from './components/Header';
import Footer from './components/Footer';
import About from './pages/about';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Contact from './pages/contact';
import BlogInner from './components/BlogInner';
import ZenTalksTemplate from './components/ZenTalksTemplate';

function App() {
  return (
    <div>
      <Header />
      <Router>
        <Routes>
          <Route path='/Home' element={<Home />} />
          <Route path='/Overview' element={<Overview />} />
          <Route path='/Platform' element={<Platform />} />
          <Route path='/Usecases' element={<Usecases />} />
          <Route path='/Resources' element={<Resources />} />
          <Route path='/About' element={<About />} />
          <Route path='/Contact' element={<Contact />} />
          <Route path='/Demo' element={<Demo />} />
          <Route path='/blog/:id' element={<BlogInner />} /> {/* Updated Route for BlogInner */}
          <Route path="/zentalks/:talkId" element={<ZenTalksTemplate />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
