import React, { useEffect } from 'react';


const Contact = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "2836830",
          formId: "b172245c-51e8-4dea-b1a8-14e194863888",
          target: '#hubspotFormContainer', // Replace with your container ID or class
        });
      }
    });

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>




    <section class="bg-1e305f position-relative contact-ban">
    <i class="shape-1"></i>
    <i class="shape-2"></i>
    <div class="container container--1 position-relative zi-9">
        <h1>Contact Us</h1>
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="contact-form">
                <div id="hubspotFormContainer">
      
    </div>  
                </div>
            </div>
        </div>
    </div>
</section>

</div>


    
  );
};

export default Contact;