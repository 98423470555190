import React, { useState, useEffect } from 'react';

function Header() {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    // Fetch header menu data
    fetch('https://dev-zenoptics-react-2024.pantheonsite.io/wp-json/wp/v2/header-menu?acf_format=standard')
      .then(response => response.json())
      .then(data => setMenuItems(data));
  }, []);

  return (
    <div>
      <header id="main-header" className="main-header bg-white">
        <nav className="navbar navbar-expand-lg ">
          <div className="container-xl nav-container-dt">
            <a className="navbar-brand" href="Home">
              <img
                height={50}
                src="/img/logo.svg"
                alt="Zen Optics"
                title="ZenOptics"
              />
            </a>
            <button className="navbar-toggler" type="button">
              <i />
              <i />
              <i />
            </button>
            <div className="" id="main-navigation">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {[...menuItems].reverse().map((item, index) => (
                  <li key={index} className="nav-item">
                    <a 
                      className="nav-link" 
                      href={item.acf.header_link} 
                      onMouseOver={(e) => e.currentTarget.style.color = '#007bff'}
                      onMouseOut={(e) => e.currentTarget.style.color = ''}
                    >
                      {item.title.rendered}
                    </a>
                  </li>
                ))}
                <li className="nav-item">
                  <a className="nav-link" href="demo">
                    Get Demo
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Header;
