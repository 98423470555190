import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ZenTalksTemplate from '../components/ZenTalksTemplate';

const ResourcesSection = () => {
  const [categories, setCategories] = useState([]);
  const [latestPost, setLatestPost] = useState(null);
  const [posts, setPosts] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null); // Track active category
  const domainName = window.location.origin; // Automatically gets your project's domain
  const apiBaseUrl = 'https://dev-zenoptics-react-2024.pantheonsite.io/wp-json/wp/v2/';

  // Function to extract the slug from the API link
  const extractSlug = (link) => {
    const parts = link.split('/');
    return parts[parts.length - 2]; // Assuming the slug is the second-to-last part of the link
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}categories`);
        if (response.data && response.data.length > 0) {
          // Filter out duplicate categories, keeping only one "ZenTalks"
          const uniqueCategories = response.data.filter((category, index, self) =>
            category.name !== 'ZenTalks' || self.findIndex(c => c.name === 'ZenTalks') === index
          );
          setCategories(uniqueCategories);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const fetchLatestPost = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}posts?per_page=1&_embed`);
        if (response.data && response.data.length > 0) {
          setLatestPost(response.data[0]);
        }
      } catch (error) {
        console.error('Error fetching latest post:', error);
      }
    };

    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}posts?per_page=50&_embed`);
        if (response.data && response.data.length > 0) {
          setPosts(response.data);
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchLatestPost();
    fetchCategories();
    fetchPosts();
  }, []);

  // Function to find category name based on category ID
  const findCategoryName = (categoryIds) => {
    const categoryNames = [];
    if (categoryIds && categories.length > 0) {
      categoryIds.forEach(categoryId => {
        const category = categories.find(cat => cat.id === categoryId);
        if (category) {
          categoryNames.push(category.name);
        }
      });
    }
    return categoryNames.join(', ');
  };

  // Function to determine the "Read More" text based on category
  const getReadMoreText = (category) => {
    switch (category) {
      case 'Blog':
        return 'Read Blog';
      case 'ZenTalks':
        return 'Watch Now';
      case 'Webinars':
        return 'Watch Now';
      case 'Reports':
        return 'See Report';
      case 'Whitepapers':
        return 'Watch Now';
      default:
        return 'Read More';
    }
  };

  // Function to generate the link based on category
  const generateLink = (category, post) => {
    switch (category) {
      case 'Blog':
        return `${domainName}/blog/${extractSlug(post.link)}`;
      case 'ZenTalks':
        return `${domainName}/zentalks/${extractSlug(post.link)}`; // Prefix "zentalks"
      case 'Press':
        return `${domainName}/press/${extractSlug(post.link)}`; // Prefix "press"
      default:
        return post.link; // No prefix needed
    }
  };

  return (
    <div>
      <div className="bg-f5f5f5 resources-main-page-in">
        <div className="container-lg container--1">
          <div className="text-center">
            <h1>Resources</h1>
          </div>
          {latestPost && (
            <div className="row res-top-row align-items-center">
              <div className="col-md-5">
                <article className="ddf-box" data-category="blog">
                  <a href={latestPost.link} className="res-a"></a>
                  <div className="ddf-box-img">
                    <div className="ddf-box-img-in" style={{backgroundImage: `url(${latestPost._embedded['wp:featuredmedia'][0].source_url})`}}></div>
                  </div>
                </article>
              </div>

              <div className="col-md-7">
                <div className="res-top-row-right">
                  <a href="blog.html" className="term-link">Blog</a>
                  <h5><a href={latestPost.link}>{latestPost.title.rendered}</a></h5>
                  <a href={`${domainName}/${extractSlug(latestPost.link)}`} className="rs-rm">Read Blog</a>
                </div>
              </div>
            </div>
          )}
          {/* Place your latest post rendering logic here */}

          {/* Tab navigation */}
          <nav>
            <div className="nav my-nav-tabs resources-tabs" id="nav-tab" role="tablist">
              <button
                className="nav-link active"
                id="nav-all-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-all"
                type="button"
                role="tab"
                aria-controls="nav-all"
                aria-selected="true"
              >
                All Resources
              </button>
              {categories.map(category => (
                <button
                  key={category.id}
                  className="nav-link"
                  onClick={() => setActiveCategory(category)}
                  data-bs-toggle="tab"
                  data-bs-target={`#nav-${category.slug}`}
                  type="button"
                  role="tab"
                  aria-controls={`nav-${category.slug}`}
                  aria-selected="false"
                >
                  {category.name}
                </button>
              ))}
            </div>
          </nav>

          {/* Tab content */}
          <div className="tab-content my-nav-tabs-content" id="nav-tabContent">
            <div
              className={`tab-pane fade show active`}
              id={`nav-all`}
              role="tabpanel"
              aria-labelledby={`nav-all-tab`}
            >
              <div className="row">
                {posts.map(post => (
                  <div key={post.id} className="col-md-6 col-lg-4 mb-3">
                    <article className="ddf-box" data-category={findCategoryName(post.categories)}>
                      <a href={generateLink(findCategoryName(post.categories), post)} className="res-a"></a>
                      <div className="ddf-box-img">
                        <div className="ddf-box-img-in" style={{backgroundImage: `url(${post._embedded['wp:featuredmedia'][0].source_url})`}}></div>
                      </div>
                      <div className="ddf-box-cnt">
                        <span className="category">Category: <a href={generateLink(findCategoryName(post.categories), post)} className="term-link">{findCategoryName(post.categories)}</a></span>
                        {/* Author information can be fetched from the API if available */}
                        <span className="author">Author: <a href="author.html" className="author-link">Author Name</a></span>
                        <h5>{post.title.rendered}</h5>
                      </div>
                      <span className="span-rm-blog">{getReadMoreText(findCategoryName(post.categories))}</span>
                    </article>
                  </div>
                ))}
              </div>
            </div>
            {/* Render tab panes for categories */}
            {categories.map(category => (
              <div
                key={category.id}
                className={`tab-pane fade`}
                id={`nav-${category.slug}`}
                role="tabpanel"
                aria-labelledby={`nav-${category.slug}-tab`}
              >
                {activeCategory && activeCategory.name === 'ZenTalks' ? (
                  <ZenTalksTemplate /> // Render ZenTalks template if ZenTalks category is active
                ) : (
                  <div className="row">
                    {/* Render posts for each category */}
                    {posts.filter(post => post.categories.includes(category.id)).map(post => (
                      <div key={post.id} className="col-md-6 col-lg-4 mb-3">
                        <article className="ddf-box" data-category={findCategoryName(post.categories)}>
                          <a href={generateLink(findCategoryName(post.categories), post)} className="res-a"></a>
                          <div className="ddf-box-img">
                            <div className="ddf-box-img-in" style={{backgroundImage: `url(${post._embedded['wp:featuredmedia'][0].source_url})`}}></div>
                          </div>
                          <div className="ddf-box-cnt">
                            <span className="category">Category: <a href={generateLink(findCategoryName(post.categories), post)} className="term-link">{findCategoryName(post.categories)}</a></span>
                            {/* Author information can be fetched from the API if available */}
                            <span className="author">Author: <a href="author.html" className="author-link">Author Name</a></span>
                            <h5>{post.title.rendered}</h5>
                          </div>
                          <span className="span-rm-blog">{getReadMoreText(findCategoryName(post.categories))}</span>
                        </article>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourcesSection;
