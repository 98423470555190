import React, { useState, useEffect } from 'react';

function FooterMenu() {
  const [menuItems, setMenuItems] = useState([]);
  const apiUrl = 'https://dev-zenoptics-react-2024.pantheonsite.io/wp-json/wp/v2/footer-menu?acf_format=standard';

  useEffect(() => {
    // Fetch menu items from API
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        // Reverse the order of menu items
        const reversedItems = data.reverse();
        setMenuItems(reversedItems);
      })
      .catch(error => console.error('Error fetching menu items:', error));
  }, []); // Empty dependency array to fetch data only once when component mounts

  return (
    <>
      <div className="col-md-6">
        <ul className="nav foot-menu-ui">
          {menuItems.map(item => (
            <li key={item.id} className="nav-item">
              <a href={item.link} className="nav-link">{item.title.rendered}</a>
            </li>
          ))}
        </ul>
        <ul className="foot-menu-adr">
          <li><i className="fa fa-solid fa-house-laptop"></i>1762 Technology Drive, Suite 108<br />San Jose, California 95110 USA</li>
          <li><i className="fa fa-solid fa-envelope"></i> <a href="mailto:info@zenoptics.com">info@zenoptics.com</a></li>
        </ul>
      </div>
      
      <div className='col-md-6'>
        <ul className="nav foot-menu-soc">
          <li><a href="https://twitter.com/ZENOPTICS" target="_blank"><i className="fa-brands fa-x-twitter"></i></a></li>
          <li><a href="https://www.linkedin.com/company/zenoptics/" target="_blank"><i className="fa-brands fa-linkedin-in"></i></a></li>
          <li><a href="https://www.youtube.com/@zenoptics" target="_blank"><i className="fa-brands fa-youtube"></i></a></li>
        </ul>
      </div>
    </>

  );
}

function Footer() {
  return (
    <footer className="footer">
      <div className="container-lg container--2">
        <div className="row">
          <FooterMenu />
          {/* Other footer components */}
        </div>
        <div className="cp-right text-center pt-5">
          <p>Copyright© 2024 ZenOptics</p>
          <a href="https://www.zenoptics.com//privacy-policy/">Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
